import React from 'react'
import styled from 'styled-components'
import Cookies from 'js-cookie';
import {Link} from 'gatsby'
import { cleanPath } from '../utils/path'

const FormWrap = styled.form`
	padding: 34px 0;
	background: white;
	border-radius: 10px;
	& label {
		width: 100%;
		font-size: 16px;
		font-weight: 700;
		color: #333333;
		text-transform: none !important;
	}
	& input {
		width: 100%;
		margin: 5px 0;
		padding: 15px;
		background: white;
		border: 1px solid #6F8D9E;
		border-radius: 4px;
	}
	& textarea {
		width: 100%;
		margin: 5px 0 18px;
		padding: 15px;
		background: white;
		border: 1px solid #6F8D9E;
		border-radius: 4px;
	}
	& select {
		width: 100%;
		margin: 5px 0 12px;
		padding: 15px;
		background: #f4f4f4;
		border: 0px;
		border-radius: 6px;
		border-right: 7px solid #f4f4f4;
		-webkit-appearance: textfield;
	  -moz-appearance: textfield;
		min-height: 2em !important;
		& option {
			padding: 5px;
			&:hover {
				background: #f4f4f4;
			}
			&:focus {
				background: #f4f4f4;
			}
		}
		&:after {
			margin-right: 20px;
		}
	}
	
	* {
		&:focus {
			outline: none !important;
		}
	}
	@media(max-width: 768px) {
		margin-top: 50px;
	}
	@media(max-width: 480px) {
		margin-top: 10px;
		padding: 30px 0px;
	}
`
const TwoColumn = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	margin-bottom: 30px;

	@media(max-width: 1024px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 30px;
	}
`
const CheckWrap = styled.div`
	display: flex;
	margin-bottom: 0px;
	& div {
		margin: 5px 20px 0 0;
	}
	& a {
		font-weight: bold;
		&:hover {
			text-decoration: underline;
		}
	}
	& input  {
		width: auto !important;
		margin-right: 10px;
	}
`
const SuccessWrap = styled.div`
	margin-top: 30px;
	display: ${props=>props.display};
	width: 100%;
	max-width: 500px;
	padding: 15px;
	color: #102A3A;
	font-weight: 700;
	text-align: center;
	background: #F6F9F9;
	border: 0px;
	border-radius: 6px;
`
const ButtonWrap = styled.div`
	width: 100%;
	margin-top: 30px;
	display: ${props=>props.display};
	@media(max-width: 480px) {
		& button {
			width: 100%;
		}
	}
`
class RequestForm extends React.Component {
	constructor(props) {
		super(props)
		this.state={
			name: "",
			email: "",
			phone: "",
			country: "",
			policy: "",
			notify: "",
			successData: {},
			formId: 'f03ed9b1-15a8-447d-828e-bc45644c3aa4',
			portalId: "7033396",
		}
		this.handleChange=this.handleChange.bind(this)
		this._handleSubmit=this._handleSubmit.bind(this)
	}
	handleChange(event) {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}
	_handleSubmit(e) {
    e.preventDefault();
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/7033396/f03ed9b1-15a8-447d-828e-bc45644c3aa4';
    const { email, name, phone, country, policy, notify } = this.state
		const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'full_name',
          value: name,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'phone',
          value: phone,
        },
        {
        	name: 'country',
        	value: country,
        },
        {
        	name: 'policy',
        	value: policy
        },
        {
        	name: 'notify',
        	value: notify,
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName
      },
    };
    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(res => {
      	res.json();
      	this.setState({
      		successData: res
      	})
      })
      .catch(err => {
        alert(err)
      });
    // I recommend setting `result` to React state
    // but you can do whatever you want
  }
	render() {
		const { successData, formId, portalId } = this.state
		return(
			<FormWrap
				data-form-id={formId}
        data-portal-id={portalId}
			 	onSubmit={this._handleSubmit}>
				<TwoColumn>
					<div>
						<label>Full name*</label>
						<input id="name" name="name" type="text" placeholder="Full Name" onChange={this.handleChange} required />
					</div>
					<div>
						<label>Email address*</label>
						<input id="email" name="email" type="email" placeholder="Email" onChange={this.handleChange} required/>
					</div>
				</TwoColumn>
				<TwoColumn>
					<div>
						<label>Phone Number*</label>
						<input id="phone" name="phone" type="tel" placeholder="Mobile Number" onChange={this.handleChange} required/>
					</div>
					<div>
						<label>Country*</label>
						<input id="country" name="country" type="text" placeholder="Input your country"  onChange={this.handleChange} required/>
					</div>
				</TwoColumn>
				<CheckWrap>
					<div><input type="checkbox" id="policy" name="policy" onChange={this.handleChange} className="regular-checkbox" /> I agree with the <Link to={cleanPath("/privacy-policy")}>Privacy Policy</Link> and <Link to={cleanPath("/privacy-policy")}>Terms of Service*</Link></div>
				</CheckWrap>
				<CheckWrap>
					<div><input type="checkbox" id="notify" name="notify" onChange={this.handleChange} className="regular-checkbox" /> I want to be notified of the latest product and feature updates</div>
				</CheckWrap>
				<SuccessWrap display={successData.status===200?"block":"none"}>
					Your form has been submitted !
				</SuccessWrap>
				<ButtonWrap display={successData.status===200?"none":"block"}>
					<button className="is-primary" type="submit">
						Submit
					</button>
				</ButtonWrap>
			</FormWrap>
		)
	}
}

export default RequestForm