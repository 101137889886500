import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import generateHTML from '../utils/generateHTML';
import Layout from '../components/Layout';
import SEO from '../components/Seo'
import Content, { HTMLContent } from '../components/Content'
import { UsecaseHeroTriangle } from '../components/Triangle'
import ContentHero from '../components/ContentHero'
import RequestForm from '../components/RequestForm'
import CtaSection from '../components/CtaSection'

export const RequestPageTemplate = ({
  hero,
  content,
}) => {
  const PostContent = HTMLContent || Content;
  return (
    <div>
      <ContentHero data={hero} />
      <UsecaseHeroTriangle />
      <section className="section">
        <div className="container">
          <PostContent
            className="content"
            content={generateHTML(content)}
          />
          <RequestForm />
        </div>
      </section>
      <CtaSection bgClassName="is-gray-bg" />
    </div>
  )
}

RequestPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  hero: PropTypes.object,
}

const RequestPage = ({ data }) => {
  const { markdownRemark } = data

  return (
    <Layout>
      <SEO
        title={markdownRemark.frontmatter.seoTitle}
        description={markdownRemark.frontmatter.seoDescription}
      />
      <RequestPageTemplate
        content={markdownRemark.html}
        hero={markdownRemark.frontmatter.hero}
      />
    </Layout>
  )
}

RequestPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default RequestPage

export const pageQuery = graphql`
  query RequestPagesById($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        hero {
          title
          subtitle
        }
      }
      html
    }
  }
`
